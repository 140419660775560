import { defineAsyncComponent } from 'vue';
import { SeamlessRouterOptions } from '@dh-io-emh/shared/src/router/types';
import { buildSeamlessRoute } from '@dh-io-emh/shared/src/utils/aem.util';
import { defineAEMCustomElement } from '@dh-io-emh/shared/src/utils/defineAEMCustomElement.util';

interface Payload {
  pdpLink: string;
}

const TAG_NAME = 'emh-search-result';

const routerOptions = (payload: Payload): SeamlessRouterOptions => ({
  routes: [
    {
      name: 'srp',
      path: buildSeamlessRoute({ path: '/' }),
      component: defineAsyncComponent(() => import('./views/srp/index.vue')),
    },
    {
      name: 'pdp',
      link: buildSeamlessRoute({ link: payload.pdpLink }),
    },
    {
      name: 'error',
      path: buildSeamlessRoute({ path: '/error' }),
      component: defineAsyncComponent(() => import('@dh-io-emh/shared/src/views/error/index.vue')),
    },
  ],
});

defineAEMCustomElement({
  // eslint-disable-next-line vue/require-name-property
  componentTag: TAG_NAME,
  MainComponent: defineAsyncComponent(() => import('./App.vue')),
  LoadingComponent: defineAsyncComponent(() => import('./components/loadingSkeletons/srpLoadingSkeleton.vue')),
  ErrorComponent: defineAsyncComponent(() => import('@dh-io-emh/shared/src/views/error/index.vue')),
  loadAppDataFn: async () => {
    const { loadSrpData } = await import('./load-srp-data');

    return loadSrpData();
  },
  routerOptions,
});
